<template>
  <div class="product" :aria-label="discoverLabel">
    <lazy-image ref="picture" v-if="imageUrl" class-name="watch" :item="picture" :sizes="pictureSizes" rootMargin="0px"></lazy-image>
    <div class="info">
      <span class="name" aria-hidden="true" v-if="product.name" v-text="product.name"></span>
      <span class="price" aria-hidden="true" v-if="getProductPrice(product)">{{ getProductPrice(product) | currency }}</span>
      <template v-if="isEcommerce">
        <button type="button" class="btn add-to-cart -icon -icon-right" v-if="esProduct && esProduct.stock && esProduct.stock.is_in_stock" @click="addToCartAndFeedback(esProduct)">
          <span>{{ $t('Add to cart') }}</span
          ><i class="icon-cart"></i>
        </button>
        <button type="button" class="btn -icon" v-else disabled>
          <i class="icon-cart"></i><span>{{ $t('Out of stock') }}</span>
        </button>
      </template>
      <router-link
        class="discover"
        :dataSku="product.sku"
        :to="product.routeParam ? `${localizedUrl}/product/${product.routeParam}` : `${localizedUrl}/product/${product.url_key}`"
        :aria-label="$t('Discover more')"
      >
        <span>{{ $t('Discover more') }}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import { ReadyHook } from 'vue-asyncready'
import ImageManager from 'vue-storefront-prismic-ssr/utilities/ImageManager'
import LazyImage from './utils/LazyImage.vue'
import { mapActions } from 'vuex'
import { getProductPrice } from '../utilities'

export default {
  props: ['product'],
  name: 'modal-product-item',
  mixins: [ReadyHook],
  components: {
    LazyImage,
  },
  created() {
    const interval = setInterval(() => {
      if (this.product) {
        const { url_key } = this.product
        this.fetchProduct(url_key).then((p) => {
          this.esProduct = p
        })
        clearInterval(interval)
      }
    }, 100)
    this.isEcommerce = this.$store.state["zenith-ui"].ecommerce;
  },
  data() {
    return {
      appeared: false,
      esProduct: null,
      isEcommerce: true,
    }
  },
  computed: {
    localizedUrl() {
      return currentStoreView().url || ''
    },
    discoverLabel() {
      let label = this.$t('Discover') + ' ' + this.product.name
      label += ' ' + (this.product.option_value_bracelet ? this.product.option_value_bracelet : this.product.raw ? this.product.raw.option_value_bracelet : '')
      label += ' ' + (this.product.option_value_material ? this.product.option_value_material : this.product.raw ? this.product.raw.option_value_material : '')
      if (getProductPrice(this.product)) {
        label += ' - ' + this.$options.filters.currency(getProductPrice(this.product))
      }
      return label
    },
    picture() {
      return {
        url: this.imageUrl,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.imageUrl, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.imageUrl, 1000, 200, 100, 1.4285714286),
        alt: this.imageAlt,
        width: this.imageSizes.width,
        height: this.imageSizes.height,
      }
    },
    pictureSizes() {
      return '(max-width: 768px) 29vw, (max-width: 992px) 26vw, 20vw'
    },
    imageUrl() {
      if (this.product.image) {
        return ImageManager.convert(this.product.image, 'image', 'product')
      } else if (this.product.images && this.product.images[0].src) {
        return ImageManager.convert(this.product.images[0].src, 'image', 'product')
      } else return null
    },
    imageSizes() {
      return {
        width: 0,
        height: 0,
      }
    },
    imageAlt() {
      if (this.product.image) {
        return this.product.name
      } else if (this.product.images && this.product.images[0].alt) {
        return this.product.images[0].alt
      } else return ''
    },
  },
  methods: {
    getProductPrice: getProductPrice,
    ...mapActions('cart', { addToCart: 'addItem' }),
    ...mapActions('feedback', ['show']),
    addToCartAndFeedback(product) {
      let size = null
      try {
        size = {
          option_value: this.esProduct.custom_options[0].values[0].option_type_id.toString(),
          option_id: this.esProduct.custom_options[0].option_id.toString(),
        }
      } catch (error) {}
      const payload = size ? { productToAdd: { ...product, product_option: { extension_attributes: { custom_options: [size] } } } } : { productToAdd: product }
      this.addToCart(payload).then(() => {
        // this.show({ message: this.$t('Your product has been added to your shopping bag') })
      })
    },
    fetchProduct(sku) {
      const storeView = currentStoreView()
      const productQuery = ({ sku }) => {
        let query = new SearchQuery()
        query = query.applyFilter({ key: 'sku', value: { eq: sku } })
        return query
      }
      return quickSearchByQuery({ query: productQuery({ sku }), entityType: 'product', storeCode: storeView.storeCode }).then(({ items }) => items[0])
    },
    generateCoordinate() {
      let coordinate = Math.floor(Math.random() * 20) + 5
      coordinate *= Math.floor(Math.random() * 2) === 1 ? 1 : -1
      return coordinate
    },
    enter() {},
  },
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.product {
  display: flex;
  flex-direction: column;
  width: 100%;

  .watch {
    width: 100%;
    flex: 0 1 57vh;
    display: flex;
    align-items: center;
    justify-content: center;

    /deep/ img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  .info {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name {
      font-size: em(17);
      line-height: em(21, 17);
      font-weight: 500;
      text-align: center;
      color: var(--zenith-theme-primary-lighter);

      @include bp(max md) {
        width: 60%;
      }
    }

    .price {
      text-align: center;
      margin-top: 1rem;
      font-size: em(12);
      line-height: em(15, 12);
      color: var(--zenith-theme-primary-lighter);
    }

    .btn {
      width: fit-content;
      margin-top: 1.5rem;
      align-content: flex-start;
      font-size: em(10);
      font-weight: 400;

      &:after {
        border-radius: 0;
      }
    }

    .discover {
      margin-top: 1.7rem;

      span {
        color: var(--zenith-theme-primary-lighter);
        text-transform: uppercase;
        font-size: em(10);
        line-height: em(10, 10);
        font-weight: bold;
        letter-spacing: 0.2em;
        padding-bottom: 0.2rem;
        border-bottom: 1px solid var(--zenith-theme-primary);
      }
    }
  }
}
</style>
